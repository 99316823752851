import { useCallback, useState } from "react";
import { IntlShape } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCustomText } from "shared/lib/golCopy";
import { isMobile } from "shared/lib/isMobile";
import { BookingFormPassengerSection } from "shared/types";

import {
  setBookingFormFieldValue,
  setBookingFormValue,
} from "../data/actions/bookingForm";
import { AppState } from "../data/reducers";
import { useCbtGuestData } from "./cbtGuestData";

export interface BookingFormSectionProps {
  index: number;
  intl: IntlShape;
}

export function useBookingFormSection({
  index,
  intl,
}: BookingFormSectionProps) {
  const {
    bookingFormSections,
    bookingFormSection,
    // eslint-disable-next-line prefer-const
    bookingFormFields,
    isFetchingServices,
  } = useSelector((state: AppState) => ({
    bookingFormSections: state.bookingForm.bookingFormSections,
    bookingFormSection: state.bookingForm.bookingFormSections[index],
    bookingFormFields: state.bookingForm.bookingFormFields,
    isFetchingServices: state.bookingForm.isFetchingServices,
  }));
  const {
    cbt,
    cbtGuestsInDynamicFormShowingSearchForm,
    cbtGuestsUsedInDynamicForm,
    phonePrefix,
    allowedTravelerTypes,
  } = useSelector((state: AppState) => ({
    cbt: state.cbt,
    cbtGuestsInDynamicFormShowingSearchForm:
      state.bookingForm.cbtGuestsInDynamicFormShowingSearchForm,
    cbtGuestsUsedInDynamicForm: state.bookingForm.cbtGuestsUsedInDynamicForm,
    phonePrefix:
      state.storage.frontendSettings.dealerFrontendSettings.phonePrefix,
    allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
  }));

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState<{
    content?: string;
    title?: string;
    alternateUrl?: string;
    showErrorText?: string;
  }>({});
  const dispatch = useDispatch();

  const indexPassenger = getIndexPassengerFromBookingFormSection(
    bookingFormSection
  );

  const cbtGuestData = useCbtGuestData({
    index,
    indexPassenger,
  });

  const toggleIsCollapsed = () => {
    dispatch(
      setBookingFormValue(
        "bookingFormSections",
        bookingFormSections.map((bookingFormSectionInside, indexSection) => {
          if (indexSection !== index || !bookingFormSectionInside.collapsible) {
            return bookingFormSectionInside;
          }

          return {
            ...bookingFormSectionInside,
            isCollapsed: !bookingFormSectionInside.isCollapsed,
          };
        })
      )
    );
  };

  const hiddenFields = ["UserPassword", "UserLogin"];

  const isGuest =
    cbt?.passengersForm?.travellers &&
    indexPassenger >= cbt.passengersForm.travellers.length;

  const showUpdateCbtGuestForLater =
    isGuest &&
    cbt.corporate?.AllowGuestFrontendSave &&
    cbtGuestsUsedInDynamicForm?.[indexPassenger];

  const uneditablePassengerName = getUneditablePassengerName({
    bookingFormSection,
    cbt,
    bookingFormFields,
    showUpdateCbtGuestForLater,
  });

  const title = getTitle({
    intl,
    title: bookingFormSection.title,
    allowedTravelerTypes,
    grades: cbt?.grades,
  });

  const showLCCText = getShowLCCText({
    bookingFormSection,
  });

  const showBookingFormSection = getShowBookingFormSection({
    bookingFormSection,
    cbt,
    cbtGuestsInDynamicFormShowingSearchForm,
  });

  const showCbtGuestAddNewGuestButton =
    isGuest &&
    !(cbtGuestData.showCbtGuestSearchForm && !showUpdateCbtGuestForLater) &&
    cbt.guestsCache?.length > 0;

  const deletePredefinedGuest = () => {
    if (cbtGuestsUsedInDynamicForm?.[indexPassenger]) {
      const newGuestsUsedInDynamicForm = cbtGuestsUsedInDynamicForm;
      delete newGuestsUsedInDynamicForm[indexPassenger];

      dispatch(
        setBookingFormValue(
          "cbtGuestsUsedInDynamicForm",
          newGuestsUsedInDynamicForm
        )
      );
    }
    bookingFormSection.elements.forEach((elementName) => {
      dispatch(
        setBookingFormFieldValue(elementName, {
          ...bookingFormFields[elementName],
          value: elementName.includes("phone_country") ? phonePrefix : "",
        })
      );
    });
  };

  const setShowCbtGuestSearchForm = (showForm) => {
    if (!cbtGuestsInDynamicFormShowingSearchForm && showForm) {
      return dispatch(
        setBookingFormValue("cbtGuestsInDynamicFormShowingSearchForm", [
          indexPassenger,
        ])
      );
    }

    const newGuestsInDynamicFormShowingSearchForm = showForm
      ? cbtGuestsInDynamicFormShowingSearchForm.concat(indexPassenger)
      : cbtGuestsInDynamicFormShowingSearchForm.filter(
          (indexInside) => indexInside !== indexPassenger
        );

    return dispatch(
      setBookingFormValue(
        "cbtGuestsInDynamicFormShowingSearchForm",
        newGuestsInDynamicFormShowingSearchForm
      )
    );
  };

  const showSaveCbtGuestForLater =
    isGuest &&
    cbt.corporate?.AllowGuestFrontendSave &&
    !cbtGuestsUsedInDynamicForm?.[indexPassenger] &&
    !cbtGuestData.showCbtGuestSearchForm;

  const modalSectionHandler = useCallback(
    async (textName: string) => {
      await setShowModal((prevShowModal) => !prevShowModal);
      if (!showModal && textName) {
        const modalDataRes = await getCustomText(textName);
        setModalData(modalDataRes);
      }
    },
    [showModal]
  );

  const indexTitle = bookingFormSection.elements.findIndex((element) =>
    element.includes("title")
  );
  const indexLastName = bookingFormSection.elements.findIndex((element) =>
    element.includes("lastname")
  );
  const indexFirstName = bookingFormSection.elements.findIndex((element) =>
    element.includes("firstname")
  );

  const indexGrade = bookingFormSection.elements.findIndex((element) =>
    element.includes("grade")
  );

  return {
    toggleIsCollapsed,
    bookingFormSection: !uneditablePassengerName
      ? bookingFormSection
      : {
          ...bookingFormSection,
          elements: bookingFormSection.elements.filter(
            (_, elIndex) =>
              elIndex !== indexTitle &&
              elIndex !== indexLastName &&
              elIndex !== indexFirstName &&
              (isMobile() ? elIndex !== indexGrade : true)
          ),
        }, // hide uneditable name fields cbt
    uneditablePassengerName,
    title,
    ...cbtGuestData,
    showCbtGuestSearchForm:
      cbtGuestData.showCbtGuestSearchForm && !showUpdateCbtGuestForLater,
    showBookingFormSection,
    showUpdateCbtGuestForLater,
    showCbtGuestAddNewGuestButton,
    deletePredefinedGuest,
    setShowCbtGuestSearchForm,
    showSaveCbtGuestForLater,
    indexGuest: indexPassenger,
    indexPassenger,
    showLCCText,
    showModal,
    modalSectionHandler,
    modalData,
    hiddenFields,
    isFetchingServices,
  };
}

export function getTitle({ title, intl, allowedTravelerTypes, grades }) {
  if (!title) return false;

  const code = title.startsWith("CODE") ? title.split("-")[2] : null;

  const getPassengerTitle = (suffix = "") =>
    `${intl.formatMessage({ id: "Passenger.title" })}${suffix}`;

  if (
    !isMobile() &&
    grades?.length > 0 &&
    code &&
    allowedTravelerTypes?.length > 0
  ) {
    const gradeName = grades.find((grade) => grade.PassengerTypeCode === code)
      ?.Name;
    if (gradeName) return getPassengerTitle(` - ${gradeName}`);
  }

  if (code && allowedTravelerTypes?.length > 0) {
    const selectedTravelerType = allowedTravelerTypes.find(
      (travelerType) => travelerType.Code === code
    );
    if (selectedTravelerType)
      return getPassengerTitle(` - ${selectedTravelerType.$t}`);
  }

  if (code) {
    return getPassengerTitle(
      ` - ${intl.formatMessage({ id: `PassengerCodes.${code}` })}`
    );
  }

  return title;
}

function getShowLCCText({ bookingFormSection }) {
  if (bookingFormSection?.code !== "other") {
    return false;
  }

  const otherSectionElements = bookingFormSection?.elements;

  // eslint-disable-next-line no-unused-vars
  const isLCC =
    otherSectionElements.includes("UserPassword") &&
    otherSectionElements.includes("UserLogin");

  // Temporary disabled
  // return isLCC;
  return false;
}

function getUneditablePassengerName({
  bookingFormSection,
  cbt,
  bookingFormFields,
  showUpdateCbtGuestForLater,
}) {
  if (!bookingFormSection?.title?.startsWith("CODE-")) {
    return false;
  }

  if (
    !cbt ||
    cbt.passengersForm?.travellers?.length + cbt.passengersForm?.guests === 0
  ) {
    return false;
  }

  const indexPassenger = getIndexPassengerFromBookingFormSection(
    bookingFormSection
  );

  if (
    !cbt?.passengersForm?.travellers ||
    (indexPassenger >= cbt.passengersForm.travellers.length &&
      !showUpdateCbtGuestForLater)
  ) {
    return false;
  }

  const titleObj = bookingFormFields[
    bookingFormSection.elements[0]
  ].options.find(
    (passengerTitleOption) =>
      passengerTitleOption?.value.toLowerCase() ===
      bookingFormFields[bookingFormSection.elements[0]]?.value.toLowerCase()
  );

  const firstname =
    bookingFormFields[
      bookingFormSection.elements.find((element) =>
        element.includes("_firstname")
      )
    ].value;
  const lastname =
    bookingFormFields[
      bookingFormSection.elements.find((element) =>
        element.includes("_lastname")
      )
    ].value;

  return `${titleObj ? titleObj.label : ""} ${firstname} ${lastname}`;
}

// hides cbt guest not filled but able to be suggested
function getShowBookingFormSection({
  bookingFormSection,
  cbt,
  cbtGuestsInDynamicFormShowingSearchForm,
}) {
  if (!cbt?.passengersForm?.guests || !cbt.passengersForm?.travellers) {
    return true;
  }

  if (
    !cbt ||
    (cbt.passengersForm?.guests || 0) +
      (cbt.passengersForm?.travellers?.length || 0) ===
      0
  ) {
    return true;
  }

  const indexPassenger = getIndexPassengerFromBookingFormSection(
    bookingFormSection
  );

  if (
    bookingFormSection.code === "passengerServices" &&
    indexPassenger >= cbt.passengersForm.travellers.length &&
    cbtGuestsInDynamicFormShowingSearchForm.includes(indexPassenger)
  ) {
    return false;
  }

  return true;
}

export function getIndexPassengerFromBookingFormSection(
  bookingFormSection: BookingFormPassengerSection
): number {
  // eslint-disable-next-line eqeqeq
  if (!bookingFormSection.elements || bookingFormSection.elements.length == 0) {
    return 0;
  }
  return Number(bookingFormSection.elements[0].split("-")[0]);
}
